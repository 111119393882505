import ListGroup from "react-bootstrap/ListGroup";
import Image from "react-bootstrap/Image";
import { Row, Col, Badge, Stack } from "react-bootstrap";

function listGroupItem(props) {
  const redirect = (p) => {
    window.open(`${p.link}`, "_blank");
  };

  return (
    <ListGroup.Item
      action
      onClick={() => redirect(props)}
      key={props.title}
      className="mt-3"
    >
      <Row className="align-items-center">
        <Col
          xs={6}
          md={2}
          className="d-flex align-items-center justify-content-center"
        >
          <div
            style={{
              width: "100px",
              height: "100px",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Image
              src={props.image}
              rounded
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </div>
        </Col>
        <Col xs={6} md={10}>
          <h4 className="font-title">{props.title}</h4>
          <h6 className="font-description">{props.description}</h6>
          <Stack direction="horizontal" gap={2} className="mt-2">
            {props.tags &&
              props.tags.map((tag, idx) => {
                return (
                  <Badge bg="primary" key={idx}>
                    {tag}
                  </Badge>
                );
              })}
          </Stack>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}

export default listGroupItem;
