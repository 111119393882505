import { Col, Image, ListGroup, Row } from "react-bootstrap";

export default function scalewayTerraform() {
  return (
    <Row>
      <Col>
        <h1>🚢 Deploy a Kubernetes cluster inside scaleway</h1>
        <p className="mt-5">
          In this tutorial, we are going to create a kubernetes cluster on
          scaleway. With a whole kuzzle ecosystem, https support and automatic
          renewal.
        </p>
        <ListGroup as="ul">
          <ListGroup.Item as="li">Traefik</ListGroup.Item>
          <ListGroup.Item as="li">Certmanager</ListGroup.Item>
          <ListGroup.Item as="li">Granafa / Loki / Prometheus</ListGroup.Item>
          <ListGroup.Item as="li">
            Kuzzle / Redis / Elasticsearch
          </ListGroup.Item>
        </ListGroup>
      </Col>
      <Row>
        <Col>
          <h2 className="mt-5">🏗️ Prerequisites</h2>
          <ListGroup as="ul">
            <ListGroup.Item
              action
              href="https://www.terraform.io/downloads.html"
              target="_blank"
            >
              Terraform
            </ListGroup.Item>
            <ListGroup.Item
              action
              href="https://www.scaleway.com/en/"
              target="_blank"
            >
              Scaleway account
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="mt-5">📽️ 1. Create a scaleway project</h2>
          <div className="text-center">
            <Image src="/scaleway/create-project.png" rounded fluid />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="mt-5">🔓 2. Create a scaleway API Key</h2>
          <p>
            In order to get the API key, you need to go to the Identity and
            Access Management (IAM) page. Then create an Application.
          </p>
          <div className="text-center">
            <Image src="/scaleway/create-application.png" rounded fluid />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            ⚠️ Remember allow the key to be used for Object Storage, else this
            key will not be able to create the bucket file to store the
            Terraform state.
          </p>
          <div className="text-center">
            <Image src="/scaleway/create-api-key.png" rounded fluid />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2 className="mt-5">📦 3. Create a scaleway Object Storage</h2>
          <p>
            In order to store the Terraform state, we need to create a bucket
            file in the Object Storage service.
          </p>
          <div className="text-center">
            <Image src="/scaleway/create-bucket.png" rounded fluid />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2 className="mt-5">🕸️ 4. Create a domain name</h2>
          <p>
            To create a domain name on Scaleway, you need to go to the Domain
            Page and search for a domain that you can own 😄.
          </p>
          <div className="text-center">
            <Image src="/scaleway/create-domain.png" rounded fluid />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2 className="mt-5">
            📄 5. Terraform code setup
          </h2>
          <p>
            Now that we have our initial setup, we can now jump into our terraform code.
          </p>
        </Col>
      </Row>
    </Row>
  );
}
